import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb } from "../components/Section"
import { BulletIcon, HomeIcon } from "../components/Icons"
import DefaultButton from "../components/Button/DefaultButton"
import BreakpointUp from "../components/Media/BreakpointUp"
import Image from "../components/image"

const Nodata = styled.div`
  margin: 0 auto;
  max-width: 1070px;
  width: 100%;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    transform: matrix(-0.87, 0.5, -0.5, -0.87, 0, 0);
    display: none;
    ${BreakpointUp.md`    
      display:block;
    `}
  }
  &:before {
    width: 83px;
    height: 83px;
    border: 8px solid #fff;
    top: 32px;
    right: 0;
  }
  &:after {
    width: 50px;
    height: 50px;
    border: 5px solid #211f28;
    top: 5px;
    right: 85px;
  }
`
const NodataFigure = styled.div`
  position: relative;
  margin-bottom: 30px;
  &:after {
    content: "";
    width: 42px;
    height: 42px;
    border: 5px solid #211f28;
    transform: matrix(-0.86, -0.52, 0.52, -0.86, 0, 0);
    position: absolute;
    top: 50%;
    left: 15px;
    z-index: 1;
    display: none;
    ${BreakpointUp.md`    
      display:block;
    `}
  }
  & .gatsby-image-wrapper {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
  }
`
const NodataContent = styled.div`
  margin: 0 auto;
  max-width: ${props => props.maxWidth};
  width: 100%;
  text-align: center;
  h1 {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 0;
    @media (min-width: 768px) {
      font-size: 44px;
      line-height: 50px;
    }
    @media (min-width: 992px) {
      font-size: 56px;
      line-height: 66px;
    }
  }
  p {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 28px;
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 30px;
    }
  }
`
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <BreadCrumb>
      <div className="container">
        <Link to="/">
          Home <BulletIcon />
        </Link>
        <span>404 Error</span>
      </div>
    </BreadCrumb>
    <Section pt="200px" pb="120px" xpt="70px" xpb="90px" bgColor="#F6F6F6">
      <div className="container">
        <Nodata>
          <NodataFigure>
            <Image name="nodata.png" alt="nodata" />
          </NodataFigure>
          <NodataContent maxWidth="735px">
            <h1>Page Not Found</h1>
            <p>
              Sorry, we can't find the page you are looking for click here to go
              back to the home page.
            </p>
            <Link to="/" className="btn-lg">
              <DefaultButton text="Go To Home" icon={<HomeIcon />} />
            </Link>
          </NodataContent>
        </Nodata>
      </div>
    </Section>
  </Layout>
)

export default NotFoundPage
